<template>
  <section class="bg-light h-full">
    <div class="container py-8">

      <div class="text-primary font-display font-bold text-5xl text-center mb-8">
        FácilRastreo
      </div>

      <div class="text-primary text-center text-lg mb-2">
        ¿Qué es el Sistema de FácilRastreo?
      </div>

      <div class="text-primary text-center text-lg mb-8">
        Comenzando desde nuestras propias granjas en Holanda, puedes seguir los controles rigurosos en cada uno de los pasos en la producción de la fórmula Friso
      </div>

      <div class="flex flex-row justify-center mb-8">
        <img class="w-52" src="/img/easy-tracking/qr-code.svg"/>
      </div>

      <div class="flex flex-row justify-center flex-wrap">
        <div
          class="item m-2"
          :style="{backgroundImage:`url(/img/easy-tracking/card-${item.letter}-bg.png)`}"
          v-for="item in items"
          :key="`item-${item.letter}`"
        >
          <div
            class="text-primary text-center text-xl font-bold leading-4 mt-20"
            v-html="item.title"
          />
        </div>
      </div>
      
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "EasyTracking",
  setup() {

    const items = [
      { letter: "a", title: "Nuestras<br/>Granjas" },
      { letter: "b", title: "Recolección<br/>de Leche" },
      { letter: "c", title: "Envasado" },
      { letter: "d", title: "Control final<br/>de calidad" },
      { letter: "e", title: "Aprobación<br/>de Exportación" },
    ];

    return {
      items,
    };
  },
});
</script>

<style scoped lang="scss">
.item {
  background-size: contain;
  background-repeat: no-repeat;
  width: 180px;
  height: 406px;
}
</style>