
import { defineComponent } from "vue";

export default defineComponent({
  name: "EasyTracking",
  setup() {

    const items = [
      { letter: "a", title: "Nuestras<br/>Granjas" },
      { letter: "b", title: "Recolección<br/>de Leche" },
      { letter: "c", title: "Envasado" },
      { letter: "d", title: "Control final<br/>de calidad" },
      { letter: "e", title: "Aprobación<br/>de Exportación" },
    ];

    return {
      items,
    };
  },
});
